<template>
  <div class="layout-container">
    <div class="silder-wrapper" v-if="showSidebar">
      <img :src="logoUrl" style="width: 50px; height: 40px; margin-left: 55px; margin-top: 15px"/>
      <ul class="sub-nav" v-for="item in childItem" style="margin-top: 15px">
        <ul v-for="it in item">
          <li>
            <router-link :to="{path: it.menuAction}">{{it.menuName}}</router-link>
          </li>
        </ul>
      </ul>
    </div>
    <div class="main-wrapper">
      <div class="nav-container">
        <ul class="nav-items">
              <li v-for="item in items">
                <a href="javascript:;" :class="{'router-link-active':$route.path.indexOf(item.menuAction)==0}"
                   @click="toggleSidebar(item.id)">{{item.menuName}}</a>
              </li>
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/ads')==0}"-->
                 <!--@click="toggleSidebar('ads')">广告管理</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/skus')==0}"-->
                 <!--@click="toggleSidebar('sku')">商品管理</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/files')==0}"-->
                 <!--@click="toggleSidebar('files')">文件管理</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/oc')==0}"-->
                 <!--@click="toggleSidebar('oc')">订单管理</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/members')==0}"-->
                 <!--@click="toggleSidebar('members')">会员管理</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/lives')==0}"-->
                 <!--@click="toggleSidebar('lives')">直播管理</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/merchants')==0}"-->
                 <!--@click="toggleSidebar('merchants')">商家管理</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/probeShops')==0}"-->
                 <!--@click="toggleSidebar('probeShops')">探店管理</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/goodShares')==0}"-->
                 <!--@click="toggleSidebar('goodShares')">好物管理</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/coupons')==0}"-->
                 <!--@click="toggleSidebar('coupons')">代金券管理</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/proposals')==0}"-->
                 <!--@click="toggleSidebar('proposals')">意见反馈管理</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/baseData')==0}"-->
                 <!--@click="toggleSidebar('baseData')">平台基础数据</a></li>-->
          <!--<li><a href="javascript:;" :class="{'router-link-active':this.$route.path.indexOf('/system')==0}"-->
                 <!--@click="toggleSidebar('system')">系统管理</a></li>-->
          <li class="liClass" style="margin-right: 50px"><a href="javascript:;">{{name}}</a></li>
          <li class="liClass"><a href="javascript:;" @click="loginout()">退出</a></li>
        </ul>
      </div>
      <div class="main-context">
        <div class="main-context-width">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {removeToken} from "../../utils/auth";
import {getMerchant} from "../../utils/auth";
import {getToken} from '../../utils/auth';
import {getMenus} from "../../api/sys/menu";

export default {
    data() {
        return {
            showSidebar: false,
            items: [],
            // items: [{
            //     "ads": [{
            //         "id": "/ads/ad",
            //         "menuName": "广告位管理",
            //         "icon": "",
            //         "menuAction": "/ads/ad",
            //         "index": "/ads/ad",
            //         "title": "广告位管理"
            //     }],
            //     "sku": [{
            //         "id": "/skus/sku",
            //         "menuName": "商品管理",
            //         "icon": "",
            //         "menuAction": "/skus/sku",
            //         "index": "/skus/sku",
            //         "title": "商品管理"
            //     }, {
            //         "id": "/skus/category",
            //         "menuName": "分类管理",
            //         "icon": "",
            //         "menuAction": "/skus/category",
            //         "index": "/skus/category",
            //         "title": "分类管理"
            //     }, {
            //         "id": "/skus/type",
            //         "menuName": "类型管理",
            //         "icon": "",
            //         "menuAction": "/skus/type",
            //         "index": "/skus/type",
            //         "title": "类型管理"
            //     }, {
            //         "id": "/skus/attribute",
            //         "menuName": "属性管理",
            //         "icon": "",
            //         "menuAction": "/skus/attribute",
            //         "index": "/skus/attribute",
            //         "title": "属性管理"
            //     }, {
            //         "id": "/skus/comment",
            //         "menuName": "商品评论",
            //         "icon": "",
            //         "menuAction": "/skus/comment",
            //         "index": "/skus/comment",
            //         "title": "商品评论"
            //     }],
            //     "files": [{
            //         "id": "/files/file",
            //         "menuName": "视频文件管理",
            //         "icon": "",
            //         "menuAction": "/files/file",
            //         "index": "/files/file",
            //         "title": "视频文件管理"
            //     }],
            //     "oc": [{
            //         "id": "/oc/order",
            //         "menuName": "订单管理",
            //         "icon": "",
            //         "menuAction": "/oc/order",
            //         "index": "/oc/order",
            //         "title": "订单管理"
            //     }],
            //     "members": [{
            //         "id": "/members/member",
            //         "menuName": "会员管理",
            //         "icon": "",
            //         "menuAction": "/members/member",
            //         "index": "/members/member",
            //         "title": "会员管理"
            //     }, {
            //         "id": "/members/shopCollect",
            //         "menuName": "店铺收藏",
            //         "icon": "",
            //         "menuAction": "/members/shopCollect",
            //         "index": "/members/shopCollect",
            //         "title": "店铺收藏"
            //     }, {
            //         "id": "/members/coupon",
            //         "menuName": "会员代金券",
            //         "icon": "",
            //         "menuAction": "/members/coupon",
            //         "index": "/members/coupon",
            //         "title": "会员代金券"
            //     }],
            //     "lives": [{
            //         "id": "/lives/live",
            //         "menuName": "直播管理",
            //         "icon": "",
            //         "menuAction": "/lives/live",
            //         "index": "/lives/live",
            //         "title": "直播管理"
            //     }],
            //     "proposals": [{
            //         "id": "/proposals/proposal",
            //         "menuName": "意见反馈管理",
            //         "icon": "",
            //         "menuAction": "/proposals/proposal",
            //         "index": "/proposals/proposal",
            //         "title": "意见反馈管理"
            //     }],
            //     "merchants": [{
            //         "id": "/merchants/merchant",
            //         "menuName": "商家管理",
            //         "icon": "",
            //         "menuAction": "/merchants/merchant",
            //         "index": "/merchants/merchant",
            //         "title": "商家管理"
            //     }, {
            //         "id": "/merchants/shop",
            //         "menuName": "店铺管理",
            //         "icon": "",
            //         "menuAction": "/merchants/shop",
            //         "index": "/merchants/shop",
            //         "title": "店铺管理"
            //     }],
            //     "probeShops": [{
            //         "id": "/probeShops/probeShop",
            //         "menuName": "探店管理",
            //         "icon": "",
            //         "menuAction": "/probeShops/probeShop",
            //         "index": "/probeShops/probeShop",
            //         "title": "探店管理"
            //     }],
            //     "goodShares": [{
            //         "id": "/goodShares/goodShare",
            //         "menuName": "好物管理",
            //         "icon": "",
            //         "menuAction": "/goodShares/goodShare",
            //         "index": "/goodShares/goodShare",
            //         "title": "好物管理"
            //     }],
            //     "coupons": [{
            //         "id": "/coupons/coupon",
            //         "menuName": "代金券管理",
            //         "icon": "",
            //         "menuAction": "/coupons/coupon",
            //         "index": "/coupons/coupon",
            //         "title": "代金券管理"
            //     }],
            //     "baseData": [{
            //         "id": "/baseData/china-division",
            //         "menuName": "国内城市地区",
            //         "icon": "",
            //         "menuAction": "/baseData/china-division",
            //         "index": "/baseData/china-division",
            //         "title": "国内城市地区"
            //     }, {
            //         "id": "/baseData/foreign-division",
            //         "menuName": "海外国家地区",
            //         "icon": "",
            //         "menuAction": "/baseData/foreign-division",
            //         "index": "/baseData/foreign-division",
            //         "title": "海外国家地区"
            //     }],
            //     "system": [{
            //         "id": "/system/department",
            //         "menuName": "组织机构",
            //         "icon": "",
            //         "menuAction": "/system/department",
            //         "index": "/system/department",
            //         "title": "组织机构"
            //     }, {
            //         "id": "/system/role",
            //         "menuName": "角色管理",
            //         "icon": "",
            //         "menuAction": "/system/role",
            //         "index": "/system/role",
            //         "title": "角色管理"
            //     }, {
            //         "id": "/system/user",
            //         "menuName": "用户管理",
            //         "icon": "",
            //         "menuAction": "/system/user",
            //         "index": "/system/user",
            //         "title": "用户管理"
            //     }, {
            //         "id": "/system/permissions",
            //         "menuName": "权限管理",
            //         "icon": "",
            //         "menuAction": "/system/permissions",
            //         "index": "/system/permissions",
            //         "title": "权限管理"
            //     }, {
            //         "id": "/system/menu",
            //         "menuName": "菜单管理",
            //         "icon": "",
            //         "menuAction": "/system/menu",
            //         "index": "/system/menu",
            //         "title": "菜单管理"
            //     }, {
            //         "id": "/system/dict",
            //         "menuName": "字典管理",
            //         "icon": "",
            //         "menuAction": "/system/dict",
            //         "index": "/system/dict",
            //         "title": "字典管理"
            //     }, {
            //         "id": "/system/password",
            //         "menuName": "密码重置",
            //         "icon": "",
            //         "menuAction": "/system/password",
            //         "index": "/system/password",
            //         "title": "密码重置"
            //     }],
            // }],
            childItem: [],
            name: '',
            logoUrl: '../../../static/img/log.jpg'
        }
    },
    watch: {
        $route() {
            this.showSidebar = this.$route.path !== '/dashboard'
        }
    },
    created() {
        const path = this.$route.path;
        this.showSidebar = path !== '/dashboard'
        if (path !== '/dashboard') {
            this.createOnload(path.split('/')[1])
        }
        this.name = localStorage.getItem('ms_username');
        this.getMenuList();
        this.getLogoUrl();
    },
    methods: {
      getLogoUrl() {
        this.logoUrl = localStorage.getItem('ms_logourl');
      },
        async getMenuList() {
            let _token = getToken();
            let res = await getMenus(_token);
            let menuIndex = res.menuIndex;
            let menuContent = res.menuContent;
            let menuItemContent = res.menuItemContent;
            let menus = menuIndex.concat(...menuContent).concat(...menuItemContent);
            this.items = this.dataFormat(menus)
            const currentPath = this.$route.path.split('/')[1];
            let currentItem = this.items.filter(item=>{
                return item.menuAction.indexOf('/'+currentPath)===0
            });
            if(currentItem.length){
                this.toggleSidebar(currentItem[0].id,true)
            }
        },
        dataFormat(arr) {
            let map = {};
            for(let i=0;i<arr.length;i++){
                let item = arr[i];
                map[item.id] = item;
            }
            let items = [];
            for(let j=0;j<arr.length;j++){
                let item = arr[j];
                if(item.menuAction==="" || item.menuAction==="null" || item.menuAction=== null){
                    item.index = item.id ;
                }else{
                    item.index = item.menuAction;
                }
                item.title = item.menuName;
                if(item.parentId){
                    if(map[item.parentId]) {
                        if(!map[item.parentId].subs){
                            map[item.parentId].subs = [];
                        }
                        map[item.parentId].subs.push(item)
                    }
                }else{
                    item.icon=item.icon|| "icon-dashboard";
                    items.push(item)
                }
            }
            return items
        },


        createOnload(index) {
            this.childItem = []
            this.items.forEach(item => {
                this.childItem.push(item[index])
            })
            let list = []
            this.childItem.forEach(item => {
                item.forEach(it => {
                    list.push(it)
                })
            })
        },
        toggleSidebar(id,defaultVal) {
            var childItem = []
            this.items.forEach(item => {
                if (id == item.id){
                    childItem.push(item.subs)
                }
            })
            this.childItem = childItem;
            if (this.childItem  == ''){
                this.$router.push('/dashboard')
                this.showSidebar = false
                return
            }
            if(!defaultVal){
                var menuAction = ''
                this.childItem.forEach(it =>{
                    this.menuAction = it[0].menuAction
                })
                this.$router.push(this.menuAction)
            }

            this.showSidebar = true
        },
        loginout() {
            removeToken();
            this.$router.push('/login');
        }
    }
};
</script>

<style lang="scss">
  .layout-container {
    display: flex;
    background-color: rgba(7, 11, 32, 1);
    min-height: 100vh;
    .silder-wrapper {
      flex: 0 0 160px;
      width: 160px;
      .logo {
        padding: 20px 0 40px 45px;
        font-size: 24px;
        color: #fff;
        font-weight: bold;
      }
      .sub-nav {
        li {
          box-sizing: border-box;
          border: 1px solid rgba(7, 11, 32, 1);
          a {
            padding-left: 53px;
            line-height: 42px;
            display: block;
            color: #fff;
            font-size: 13px;
            &.router-link-active,
            &:hover {
              color: rgb(24, 144, 255);
            }
          }
        }
      }
    }
    .main-wrapper {
      flex: 1;
      .nav-container {
        width: 100%;
        height: 64px;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: rgba(7, 11, 32, 1);
        border-radius: 0px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        .nav-items {
          padding-left: 36px;
          height: 64px;
          li {
            display: inline-block;
            a {
              display: block;
              padding: 0 18px;
              line-height: 64px;
              font-size: 14px;
              color: #E4E4E4;
              &.router-link-active,
              &:hover {
                color: rgb(40, 120, 255);
              }
            }
          }
        }
        .liClass {
          float: right;
          height: 0px;
        }
      }
      .main-context {
        padding: 20px 20px 10px 44px;
        background: rgb(241, 245, 251);
        height: calc(100vh - 94px);
        border-radius: 16px 0 0 0;
        overflow-y: auto;
      }
    }
    .main-context-width {
      max-width: 1560px;
    }
  }
   @media screen and  (max-width: 1800px) {
    .layout-container .main-wrapper .nav-container .nav-items li a{
      padding:0 12px;
    }
  }
</style>
