import Cookies from 'js-cookie'

const TokenKey = 'zb-backend';

export function getToken() {
  console.log(Cookies)
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token,{ expires: 30 })
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}
