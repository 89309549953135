import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../components/layout/Entry';

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const dashboard = [
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('../views/dashboard/dashboard.vue'),
        meta: {title: '我的首页'}
      },
    ]
  },
  {
    path: '/login',
    component: () => import('../views/Login/login.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
];


const member = [
  {
    path: '/members',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'member',
        component: () => import('../views/member/entry.vue'),
        meta: {title: '会员管理'}
      }, {
        path: 'member/memberInfo',
        component: () => import('../views/member/info.vue'),
        meta: {title: '会员详情'}
      },
    ]
  },
];

//商城
const microMall = [
  {
    path: '/microMalls',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'onSale',
        component: () => import('../views/micro-mall/sale-sku/entry.vue'),
        meta: {title: '销售中'}
      },

      {
        path: 'notShelf',
        component: () => import('../views/micro-mall/shelf-sku/entry.vue'),
        meta: {title: '未上架'}
      },
      {
        path: 'saveService',
        component: () => import('../views/micro-mall/shelf-sku/serviceInsert.vue'),
        meta: {title: '新增服务类商品'}
      },
      {
        path: 'updateService',
        component: () => import('../views/micro-mall/shelf-sku/serviceEdit.vue'),
        meta: {title: '编辑服务类商品'}
      },
      {
        path: 'saveSale',
        component: () => import('../views/micro-mall/shelf-sku/saleInsert.vue'),
        meta: {title: '新增卖品类商品'}
      },
      {
        path: 'updateSale',
        component: () => import('../views/micro-mall/shelf-sku/saleEdit.vue'),
        meta: {title: '编辑卖品类商品'}
      },
      {
        path: 'orderList',
        component: () => import('../views/micro-mall/order/entry.vue'),
        meta: {title: '订单管理'}
      },
      {
        path: 'category',
        component: () => import('../views/micro-mall/category/entry.vue'),
        meta: {title: '分类设置'}
      },
    ]
  },
];

//商品管理
const sku = [
  {
    path: '/skus',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'brand',
        component: () => import('../views/brand/entry.vue'),
        meta: {title: '品牌管理'}
      },
      {
        path: 'sku',
        component: () => import('../views/sku/entry.vue'),
        meta: {title: '商品管理'}
      },
      {
        path: 'saveSku',
        component: () => import('../views/sku/insert.vue'),
        meta: {title: '商品新增'}
      }, {
        path: 'updateSku',
        component: () => import('../views/sku/edit.vue'),
        meta: {title: '商品编辑'}
      },
      {
        path: 'supplier',
        component: () => import('../views/supplier/entry.vue'),
        meta: {title: '供货商管理'}
      },

      {
        path: 'saveSupplier',
        component: () => import('../views/supplier/insert.vue'),
        meta: {title: '供货商新增'}
      },
      {
        path: 'updateSupplier',
        component: () => import('../views/supplier/edit.vue'),
        meta: {title: '供货商编辑'}
      },
      {
        path: 'stock',
        component: () => import('../views/stock/entry.vue'),
        meta: {title: '供货商编辑'}
      },
    ]
  },
];


//员工管理
const employee = [
  {
    path: '/employees',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'employee',
        component: () => import('../views/employee/entry.vue'),
        meta: {title: '员工管理'}
      },
      {
        path: 'saveEmployee',
        component: () => import('../views/employee/insert.vue'),
        meta: {title: '员工新增'}
      },
      {
        path: 'updateEmployee',
        component: () => import('../views/employee/edit.vue'),
        meta: {title: '员工编辑'}
      },
    ]
  },
];
//预约设置
const appointment = [
  {
    path: '/appointments',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'appointment',
        component: () => import('../views/appointment/entry.vue'),
        meta: {title: '预约设置'}
      },

      {
        path: 'saveAppointment',
        component: () => import('../views/appointment/insert.vue'),
        meta: {title: '预约新增'}
      },

      {
        path: 'updateAppointment',

        component: () => import('../views/appointment/edit.vue'),
        meta: {title: '预约编辑'}
      },
    ]
  },
];

//轮牌管理
const roundPlate = [
  {
    path: '/roundPlates',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'roundPlate',
        component: () => import('../views/round-plate/entry.vue'),
        meta: {title: '轮牌管理'}
      },
      {
        path: 'saveRoundPlate',
        component: () => import('../views/round-plate/insert.vue'),
        meta: {title: '轮牌新增'}
      },
      {
        path: 'updateRoundPlate',
        component: () => import('../views/round-plate/edit.vue'),
        meta: {title: '轮牌编辑'}
      },
      {
        path: 'roundCard',
        component: () => import('../views/round-plate/roundCard.vue'),
        meta: {title: '轮牌'}
      },
    ]
  },
];

const baseData = [
  {
    path: '/baseData',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'serviceItem',
        component: () => import('../views/service-item/entry.vue'),
        meta: {title: '服务项目设置'}
      },
      {
        path: 'saveServiceItem',
        component: () => import('../views/service-item/insertServiceItem.vue'),
        meta: {title: '服务项目新增'}
      },
      {
        path: 'updateServiceItem',
        component: () => import('../views/service-item/editServiceItem.vue'),
        meta: {title: '服务项目编辑'}
      },
      {
        path: 'savePackage',
        component: () => import('../views/service-item/insertPackage.vue'),
        meta: {title: '套餐新增'}
      },
      {
        path: 'updatePackage',
        component: () => import('../views/service-item/editPackage.vue'),
        meta: {title: '套餐编辑'}
      },
      {
        path: 'category',
        component: () => import('../views/category/entry.vue'),
        meta: {title: '基础分类设置'}
      },
      {
        path: 'card',
        component: () => import('../views/mb-cards/entry.vue'),
        meta: {title: '会员卡设置'}
      },
      {
        path: 'saveCard',
        component: () => import('../views/mb-cards/card/insert.vue'),
        meta: {title: '会员卡新增'}
      },
      {
        path: 'updateCard',
        component: () => import('../views/mb-cards/card/edit.vue'),
        meta: {title: '会员卡编辑'}
      },
      {
        path: 'payParam',
        component: () => import('../views/pay-param/entry.vue'),
        meta: {title: '支付参数列表'}
      },
      {
        path: 'china-division',
        component: () => import('../views/china-division/entry.vue'),
        meta: {title: '国内城市地区'}
      }, {
        path: 'foreign-division',
        component: () => import('../views/foreign-division/entry.vue'),
        meta: {title: '海外国家地区'}
      },

    ]
  },
];

const ad = [
  {
    path: '/ad',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'entryAd',
        component: () => import('../views/ad/entry.vue'),
        meta: {title: '轮播图管理'}
      },
      {
        path: 'saveAd',
        component: () => import('../views/ad/insert.vue'),
        meta: {title: '新增轮播图'}
      },
      {
        path: 'updateAd',
        component: () => import('../views/ad/edit.vue'),
        meta: {title: '编辑轮播图'}
      },
    ]
  },
];

const system = [
  {
    path: '/system',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'department',
        component: () => import('../views/sys/department/entry.vue'),
        meta: {title: '组织机构'}
      }, {
        path: 'role',
        component: () => import('../views/sys/role/entry.vue'),
        meta: {title: '角色管理'}
      }, {
        path: 'user',
        component: () => import('../views/sys/user/entry.vue'),
        meta: {title: '用户管理'}
      }, {
        path: 'permissions',
        component: () => import('../views/sys/permissions/entry.vue'),
        meta: {title: '权限管理'}
      }, {
        path: 'menu',
        component: () => import('../views/sys/menu/entry.vue'),
        meta: {title: '菜单管理'}
      }, {
        path: 'dict',
        component: () => import('../views/sys/dict/entry.vue'),
        meta: {title: '字典管理'}
      }, {
        path: 'password',
        component: () => import('../views/sys/password/entry.vue'),
        meta: {title: '密码重置'}
      },{
        path: 'departmentInfo',
        component: () => import('../views/sys/department-info/entry.vue'),
        meta: {title: '门店设置'}
      },
    ]
  },
];

const statistics = [
  {
    path: '/statistics',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dataPanel',
        component: () => import('../views/data-panel/entry.vue'),
        meta: {title: '数据看版'}
      },
      {
        path: 'itemStatistics',
        component: () => import('../views/item-statistics/entry.vue'),
        meta: {title: '项目分类统计'}
      },
      {
        path: 'packageStatistics',
        component: () => import('../views/package-statistics/entry.vue'),
        meta: {title: '套餐统计'}
      },
      {
        path: 'skuStatistics',
        component: () => import('../views/sku-statistics/entry.vue'),
        meta: {title: '商品统计'}
      },
      {
        path: 'employeeStatistics',
        component: () => import('../views/employee-statistics/entry.vue'),
        meta: {title: '员工业绩统计'}
      },
      {
        path: 'memberAnalysis',
        component: () => import('../views/member-analysis/entry.vue'),
        meta: {title: '会员分析'}
      },
      {
        path: 'storeItem',
        component: () => import('../views/store-item/entry.vue'),
        meta: {title: '门店项目部'}
      },

      {
        path: 'salesBusiness',
        component: () => import('../views/sales-business/entry.vue'),
        meta: {title: '销售事业部'}
      },

      {
        path: 'cooperativeBusiness',
        component: () => import('../views/store-item/entry.vue'),
        meta: {title: '合作商家客户统计'}
      },
      {
        path: 'haikuan',
        component: () => import('../views/sales-business/entry.vue'),
        meta: {title: '销售事业部'}
      },
      {
        path: 'operationCenter',
        component: () => import('../views/operation-center/entry.vue'),
        meta: {title: '运营中心'}
      },
    ]
  },
];


//提成管理
const commi = [
  {
    path: '/commis',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'itemCommission',
        component: () => import('../views/item-commission/entry.vue'),
        meta: {title: '项目提成配置'}
      },
      {
        path: 'itemCommission/itemBase',
        component: () => import('../views/item-commission/base.vue'),
        meta: {title: '设置项目提成配置'}
      },
      {
        path: 'packageCommission',
        component: () => import('../views/package-commission/entry.vue'),
        meta: {title: '套餐提成配置'}
      },
      {
        path: 'skuCommission',
        component: () => import('../views/sku-commission/entry.vue'),
        meta: {title: '卖品提成配置'}
      },
      {
        path: 'cardCommission',
        component: () => import('../views/card-commission/entry.vue'),
        meta: {title: '开卡续卡提成配置'}
      },
    ]
  },
];

//开店宝小程序
const shop = [
  {
    path: '/shop',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'custom',
        component: () => import('../views/open_shop/custom/custom.vue'),
        meta: {title: '自定义配置'}
      },
      {
        path: 'range',
        component: () => import('../views/open_shop/permission-config/entry.vue'),
        meta: {title: '权限配置'}
      },
      {
        path: 'permissionRole',
        component: () => import('../views/open_shop/permission-role/entry.vue'),
        meta: {title: '权限角色配置'}
      },
      {
        path: 'mbUser',
        component: () => import('../views/open_shop/mb-user/entry.vue'),
        meta: {title: '用户管理'}
      },
      {
        path: 'shift',
        component: () => import('../views/shift/entry.vue'),
        meta: {title: '考勤方案'}
      },
      {
        path: 'vacation',
        component: () => import('../views/vacation/entry.vue'),
        meta: {title: '假期管理'}
      },
      {
        path: 'wifi',
        component: () => import('../views/wifi/entry.vue'),
        meta: {title: '考勤设置'}
      },
    ]
  },
];
const routes = [...dashboard, ...member, ...baseData, ...system, ...sku, ...employee, ...appointment, ...roundPlate, ...microMall, ...ad, ...statistics,...commi,...shop,];


export default new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})
