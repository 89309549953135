let dev = process.env.NODE_ENV !== 'production';
let host = dev ? 'http://localhost:21510' : 'http://backend.xiaoguanjia.net';
// let host = dev ? 'http://192.168.10.145:21410' : 'http://backend.jiuguodian.com';
const port = '21510';
const Base_url = host;

export {
    Base_url,
    port,
    host
}






