<template>
  <el-form-item :label="label">
    <template v-if="url">
      <div style="display: flex" class="video-uploader2">
        <el-upload
            :show-file-list="false"
            :action="uploadUrl"
            :on-progress="progressEvent"
            :before-upload="beforeUpload"
            class="reload-update"
            :on-success="videoSuccess">
          <video controls="" width="300px" height="300px" name="media" v-if="url" >
            <source :src="url" type="video/mp4">
          </video>
        </el-upload>
        <slot>
          <el-button size="small" class="video-remove" style="margin-left:10px;" @click.stop="deleteVideo" type="text">删除</el-button>
        </slot>
      </div>
    </template>
    <el-row type="flex" v-else>
      <el-upload
          :on-progress="progressEvent"
          :before-upload="beforeUpload"
          :show-file-list="false"
          class="video-uploader avatar-uploader1"
          :action="uploadUrl"
          :on-success="videoSuccess">
        <el-progress type="circle" v-if="start" :percentage="progress"></el-progress>
        <video :src="url" v-if="url" style="width: 148px;height: 148px"></video>
        <el-button v-else size="small" type="text" icon="el-icon-plus"></el-button>
      </el-upload>
    </el-row>
  </el-form-item>
</template>

<script>
  import {Base_url} from "../../../config";

  export default {
    data() {
      return {
        uploadUrl: this.action,
        start: false,
        progress: 0,
        url: this.videoUrl
      }
    },
    watch: {
      videoUrl(url) {
        this.start = false;
        this.url = url
      }
    },
    props: {
      action: {
        type: String,
        default: Base_url + '/admin/upload'
      },
      label: String,
      videoUrl: String,
      videoSuccess: Function,
      deleteVideo: {
        type: Function,
        default: function () {

        }
      }
    },
    methods: {
      beforeUpload(file) {
          console.log(file.size)
          const isMP4 = file.type === 'video/mp4';
          const isLt50M = file.size / 1024 / 1024 < 50;
          if (!isMP4) {
              this.$message.error('请上传MP4格式的视频!');
              return false;
          }
          if (!isLt50M) {
              this.$message.error('视频大小不能超过50MB');
              return false;
          }
        this.url = '';
        this.progress = 0;
        this.start = true;
      },
      progressEvent(event) {
        let percent = parseInt(event.percent);
        this.progress = percent > 90 ? percent - 3 : percent;
      }
    }
  }
</script>

<style >
  .reload-update{
    margin-right: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px!important;
    height:300px!important;
    background: rgb(251,253,255);
    border: 1px dashed #d9d9d9;
  }
   .video-remove{
    position: absolute;
    bottom: 0px;
     left: 50%;
     transform: translateX(-50%);
  }
  .video-uploader{
    width: 300px!important;
    height:300px!important;
  }
  .video-uploader .el-button--text{
    padding-top: 130px;
  }
  .avatar-uploader1 .el-upload:hover{
    border: 1px dashed #d9d9d9!important;
  }
  .video-uploader2 .el-upload--text{
    width: 100%;
    height: 100%;
  }
</style>
