import router from './router/index';
import {Message} from "element-ui";
import { getToken, removeToken } from './utils/auth'
import {  checkToken } from './api/login'

const whiteList = ['/login', '/404', '/403'];


router.beforeEach(async (to, from, next) => {
    let _token = getToken();
    if (_token) {
        checkToken(_token).then(res=>{
            if (res==true) {
                if (whiteList.indexOf(to.path) !== -1) {
                    next('/')
                } else {
                    next()
                }
            } else {
                Message('登录超时,请重新登录！')
                removeToken();
                next('/login')
            }
        }).catch(res=>{
            Message('登录超时,请重新登录！')
            removeToken();
            next('/login')
        });

    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/login')
        }
    }
})

