import axios from 'axios'
import {createSearchParams} from '../../utils/main'
import { Base_url} from '../../config'


//获取菜单列表
function getMenuList(){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getAllMenu',
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//获取权限列表
function getPermissions(){
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getPermissions',
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//添加顶级菜单
function addRoot(name,sort){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/menu/root',
            params:{
                menuName:name,
                sort:sort
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//添加权限
function saveMenu(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/save_menu',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//更新
function updateMenu(data){
    return new Promise(resolve => {
        axios({
            method: 'post',
            url: Base_url+'/admin/update_menu',
            data: createSearchParams(data)
        }).then((res)=>{
            resolve(res.data)
        })
    })
}
//删除权限
function deleteMenu(id){
    return new Promise(resolve => {
        axios({
            method: 'delete',
            url: Base_url+'/admin/deleteMenu',
            params:{
                id:id
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

//根据角色获取菜单
function getMenus(token) {
    return new Promise(resolve => {
        axios({
            method: 'get',
            url: Base_url+'/admin/getMenuByUid',
            params:{
                token:token
            }
        }).then((res)=>{
            resolve(res.data)
        })
    })
}

export {
    getMenuList,
    addRoot,
    deleteMenu,
    saveMenu,
    getPermissions,
    updateMenu,
    getMenus
}
